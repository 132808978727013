import { MemberPageLayoutComponent } from './layouts/memberpage/memberpage-layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPageLayoutComponent } from './layouts/mainpage/mainpage-layout.component';
import { memberRoute, pageRoute } from './routes/full-layout.routes';

const routes: Routes = [
  {
    path: '',
    component: MainPageLayoutComponent,
    children: pageRoute,
  },
  {
    path: 'member',
    component: MemberPageLayoutComponent,
    children: memberRoute,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
