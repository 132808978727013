<header id="topnav" class="defaultscroll sticky" style="background-color: #f7f7f7;">
  <div class="container">
    <a class="logo" (click)="goToPage('/')">
      <span class="logo-light-mode">
        <img src="assets/logo/prima-new-logo.png" class="l-dark" height="54" alt="">
        <img src="assets/logo/prima-new-logo.png" class="l-light" height="54" alt="">
      </span>
      <img src="assets/logo/prima-new-logo.png" height="54" class="logo-dark-mode" alt="">
    </a>
    <div class="menu-extras">
      <div class="menu-item">
        <a class="navbar-toggle" [ngClass]="toggleMemberMenu ? 'open' : ''" id="isToggle" (click)="toggleMenus()">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </div>
    </div>
    <ul class="buy-button list-inline mb-0">
      <!-- <li class="list-inline-item ps-1 mb-0">
        <a (click)="logoutAccount()">
          <span class="btn btn-icon btn-pills btn-brand"><i class='bx bx-log-out'></i></span>
        </a>
      </li> -->
    </ul>
    <div id="navigation" [ngStyle]="{'display': toggleMemberMenu ? 'block' : 'none'}">
      <ul class="navigation-menu nav-dark" style="background-color: rgba(247, 247, 247, 0.1) !important;">
        <li><a class="sub-menu-item" (click)="goToPage('/member')">Home</a></li>
        <li><a class="sub-menu-item" (click)="goToPage('/member/profile')">Profile</a></li>
        <li class="has-submenu parent-menu-item">
          <a href="javascript:void(0)">Transactions</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li><a (click)="goToPage('/member/transaction-history')" class="sub-menu-item">Transaction History</a></li>
            <li><a (click)="goToPage('/member/transaction-pending')" class="sub-menu-item">Transaction Pending</a></li>
          </ul>
        </li>
        <li><a class="sub-menu-item" (click)="goToPage('/member/beneficiery')">Beneficiery</a></li>
        <li><a class="sub-menu-item" (click)="logoutAccount()">Logout</a></li>
      </ul>
    </div>
  </div>
</header>

<router-outlet></router-outlet>

<footer class="footer" style="position: fixed; bottom: 0; width: 100%; z-index: 1000;">
  <div class="footer-py-20 bg-light">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center">
            <p class="mb-0 text-footer">&copy; {{ dateNow }}. Prima Express</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<a href="#" onclick="topFunction()" id="back-to-top" class="back-to-top fs-5"><i data-feather="arrow-up"
    class="fea icon-sm icons align-middle"></i></a>

<script src="js/bootstrap.bundle.min.js"></script>
<script src="js/tiny-slider.js "></script>
<script src="js/tobii.min.js "></script>
<script src="js/feather.min.js"></script>
<script src="js/switcher.js"></script>
<script src="js/plugins.init.js"></script>
<script src="js/app.js"></script>
