import { RouteHelper } from './../../helpers/route.helpers';
import { StorageDataService } from './../../helpers/storageData.service';
import { Component, DoCheck, OnInit } from '@angular/core';
import { ProfileDataDTO } from '../../../app/dto/account/profile.dto';
import * as moment from 'moment';
import * as $ from 'jquery';

@Component({
  selector: 'app-mainpage-layout',
  templateUrl: './mainpage-layout.component.html',
  styleUrls: ['./mainpage-layout.component.scss'],
})
export class MainPageLayoutComponent implements OnInit, DoCheck {
  constructor(
    public routeHelper: RouteHelper,
    private storageDataService: StorageDataService
  ) {
    this.dateNow = moment().format('YYYY');

    this.dataAuth = null;
    this.logged = false;
  }

  dateNow: string;
  dataAuth: any;
  toggleMemberMenu: boolean;
  logged: boolean;

  dataProfile!: ProfileDataDTO;

  async ngOnInit(): Promise<void> {
    this.toggleMemberMenu = false;
  }

  ngDoCheck() {
    this.dataAuth = this.storageDataService.getAuthDataStorage();
    this.dataProfile = this.storageDataService.getProfileStorage();
    const currentTime = moment();
    if (
      this.dataAuth &&
      this.dataProfile &&
      currentTime.isBefore(moment(this.dataAuth.expires))
    ) {
      this.logged = true;
    }
  }

  toggleMenus() {
    this.toggleMemberMenu = !this.toggleMemberMenu;
  }

  goToPage(url: string) {
    this.routeHelper.navigated(url);
    this.toggleMemberMenu = false;
  }
}
