import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RouteHelper {
  constructor(private router: Router) {}

  navigated(url: string) {
    this.router.navigate([url]);
  }
}
