import { Routes } from '@angular/router';

export const pageRoute: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../../app/pages/pages.module').then(
        (m) => m.PagesModule
      ),
  },
];

export const memberRoute: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../../app/pages-member/pages-member.module').then(
        (m) => m.PageMemberModule
      ),
  },
];
