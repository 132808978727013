export const dataEnum = {
  customer: 'CUSTOMER',
  admin: 'ADMIN',
  userType: 'userType',

  // ekyc
  verified: 'Verified',
  decline: 'Decline',
  waitingApproval: 'Waiting for Approval',

  // storage name
  profile: 'PROFILE',
  ekyc: 'EKYC',
  auth: 'AUTH',
  menu: 'MENU'
};
