<header id="topnav" class="defaultscroll sticky">
  <div class="container">
    <a class="logo" (click)="goToPage('/')">
      <span class="logo-light-mode">
        <img src="assets/logo/prima-new-logo.png" class="l-dark" height="54" alt="">
        <img src="assets/logo/prima-new-logo.png" class="l-light" height="54" alt="">
      </span>
      <img src="assets/logo/prima-new-logo.png" height="54" class="logo-dark-mode" alt="">
    </a>
    <div class="menu-extras">
      <div class="menu-item">
        <a class="navbar-toggle" [ngClass]="toggleMemberMenu ? 'open' : ''" id="isToggle" (click)="toggleMenus()">
          <div class="lines" >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </div>
    </div>
    <ul class="buy-button list-inline mb-0">
      <li class="list-inline-item ps-1 mb-0" *ngIf="!logged">
        <a (click)="goToPage('/login')">
          <span class="btn btn-icon btn-pills btn-brand"><i class='bx bx-log-in-circle'></i></span>
        </a>
      </li>
      <li class="list-inline-item ps-1 mb-0" *ngIf="logged">
        <a (click)="goToPage('/member')">
          <span class="btn btn-icon btn-pills btn-brand"><i class='bx bx-user'></i></span>
        </a>
      </li>
    </ul>
    <div id="navigation" [ngStyle]="{'display': toggleMemberMenu ? 'block' : 'none'}">
      <ul class="navigation-menu nav-dark">
        <li>
          <a class="sub-menu-item" (click)="goToPage('/')">
            Home
          </a>
        </li>
        <li>
          <a class="sub-menu-item" (click)="goToPage('/how-to-verify-identity')">
            How To Verify Identity
          </a>
        </li>
        <li>
          <a class="sub-menu-item" (click)="goToPage('/services')">
            Services
          </a>
        </li>
        <li>
          <a class="sub-menu-item" (click)="goToPage('/our-agents')">
            Our Agents
          </a>
        </li>
      </ul>
    </div>
  </div>
</header>

<router-outlet></router-outlet>

<footer class="footer" style="background-color: #14279B !important;">
  <div class="container">
    <div class="row justify-content-center" style="padding-top: 20px;">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4 color-white">Contact Us</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h5 class="title color-white">Office Hours:</h5>
        <h6 class="color-white">Mon-Friday: 9:00AM to 5:00PM (GMT+8:00)</h6>
        <h6 class="color-white">Call Us <i class='bx bxs-phone'></i> : +673 2455478</h6>
        <h6 class="color-white">Mail Us <i class='bx bx-envelope' ></i> : admin@primaxpress.com</h6>
        <p class="color-white">E-mails are typically responded to within 24hours.</p>
      </div>
    </div>
  </div>

  <div class="footer-py-20 bg-light">
    <div class="container text-center">
      <div class="row align-items-center">
        <div class="col-sm-12 text-center">
          <p class="text-footer mb-0">&copy; {{ dateNow }}. Prima Express Limited</p>
        </div>
      </div>
    </div>
  </div>
</footer>

<a href="#" onclick="topFunction()" id="back-to-top" class="back-to-top fs-5">
  <i class='bx bx-up-arrow-alt icons align-middle'></i>
</a>

<script src="js/bootstrap.bundle.min.js"></script>
<script src="js/tiny-slider.js "></script>
<script src="js/tobii.min.js "></script>
<script src="js/feather.min.js"></script>
<script src="js/switcher.js"></script>
<script src="js/plugins.init.js"></script>
<script src="js/app.js"></script>
