import { CommonResponseDTO } from './../../dto/api/api-response.dto';
import { environment } from './../../../environments/environment';
import { dataEnum } from './../../helpers/data.enum';
import { EncryptDataService } from './../../helpers/encrypt.service';
import { AuthData, User, AuthMemberPayloadDto, RegisterUser, ResetPasswordDTO } from './../../dto/auth/auth.dto';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<AuthData>;
  public currentUser: Observable<AuthData>;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private encryptDataService: EncryptDataService
  ) {
    this.currentUserSubject = new BehaviorSubject<AuthData>(
      JSON.parse(this.encryptDataService.decryptData(localStorage.getItem(dataEnum.auth)))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): AuthData {
    return this.currentUserSubject.value;
  }

  getMenu(param: any): Observable<CommonResponseDTO> {
    return this.http.get<CommonResponseDTO>(environment.apiUrl + '/menu', {
      params: param,
    });
  }

  authentication(payload: AuthMemberPayloadDto): Observable<CommonResponseDTO> {
    return this.http.post<CommonResponseDTO>(
      `${environment.apiUrl}/authtentication`,
      payload,
      this.httpOptions
    );
  }

  registerUser(payload: RegisterUser): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/registration`,
      payload,
      this.httpOptions
    );
  }

  forgotPassword(param) {
    return this.http.post<CommonResponseDTO>(`${environment.apiUrl}/forgot-password`,
        param,
        this.httpOptions
      );
  }

  resetPassword(param: ResetPasswordDTO) {
    return this.http.post<CommonResponseDTO>(`${environment.apiUrl}/reset-password`,
        param,
        this.httpOptions
      );
  }

  checkToken(param) {
    return this.http.post<CommonResponseDTO>(`${environment.apiUrl}/check-token`,
        param,
        this.httpOptions
      );
  }

  logout(): void {
    // remove user from local storage and set current user to null
    localStorage.removeItem(dataEnum.auth);
    localStorage.removeItem(dataEnum.profile);
    localStorage.removeItem(dataEnum.ekyc);
    localStorage.removeItem(dataEnum.menu);
    this.router.navigate(['/login']);
  }
}
